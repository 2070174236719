import React, { Component } from "react"

class ShareIcons extends Component {
  render() {
    const { article } = this.props
    const twitterShare = `http://twitter.com/share?text=${
      article.frontmatter.title
    }&url=https://codeparadox.in${article.frontmatter.path}/&hashtags=${
      article.frontmatter.categories
    }/&via=i_amsurajsharma`

    const facebookShare = `https://facebook.com/sharer/sharer.php?u=https://codeparadox.in${
      article.frontmatter.path
    }`

    const linkedinShare = `https://www.linkedin.com/shareArticle?mini=true&url=https://codeparadox.in${
      article.frontmatter.path
    }&title=${encodeURIComponent(
      article.frontmatter.title
    )}&summary=${encodeURIComponent(
      article.frontmatter.description
    )}&source=LinkedIn`
    return (
      <div className="share-icon-container">
        <h3>If you liked this post? Share it !</h3>
        <div className="share-icons">
          <a
            href={twitterShare}
            rel="noopener noreferrer"
            target="_blank"
            className="twitter-share"
          >
            <svg
              version="1.1"
              id="Capa_1"
              x="0px"
              y="0px"
              viewBox="0 0 112.197 112.197"
              style={{ enableBackground: "new 0 0 112.197 112.197" }}
            >
              <g>
                <circle
                  style={{ fill: "#55ACEE" }}
                  cx="56.099"
                  cy="56.098"
                  r="56.098"
                />
                <g>
                  <path
                    style={{ fill: "#F1F2F2" }}
                    d="M90.461,40.316c-2.404,1.066-4.99,1.787-7.702,2.109c2.769-1.659,4.894-4.284,5.897-7.417
			c-2.591,1.537-5.462,2.652-8.515,3.253c-2.446-2.605-5.931-4.233-9.79-4.233c-7.404,0-13.409,6.005-13.409,13.409
			c0,1.051,0.119,2.074,0.349,3.056c-11.144-0.559-21.025-5.897-27.639-14.012c-1.154,1.98-1.816,4.285-1.816,6.742
			c0,4.651,2.369,8.757,5.965,11.161c-2.197-0.069-4.266-0.672-6.073-1.679c-0.001,0.057-0.001,0.114-0.001,0.17
			c0,6.497,4.624,11.916,10.757,13.147c-1.124,0.308-2.311,0.471-3.532,0.471c-0.866,0-1.705-0.083-2.523-0.239
			c1.706,5.326,6.657,9.203,12.526,9.312c-4.59,3.597-10.371,5.74-16.655,5.74c-1.08,0-2.15-0.063-3.197-0.188
			c5.931,3.806,12.981,6.025,20.553,6.025c24.664,0,38.152-20.432,38.152-38.153c0-0.581-0.013-1.16-0.039-1.734
			C86.391,45.366,88.664,43.005,90.461,40.316L90.461,40.316z"
                  />
                </g>
              </g>
            </svg>
          </a>
          <a
            href={facebookShare}
            target="_blank"
            rel="noopener noreferrer"
            className="fb-share"
          >
            <svg
              x="0px"
              y="0px"
              viewBox="0 0 408.788 408.788"
              style={{ enableBackground: "new 0 0 408.788 408.788" }}
            >
              <path
                style={{ fill: "#fff" }}
                d="M353.701,0H55.087C24.665,0,0.002,24.662,0.002,55.085v298.616c0,30.423,24.662,55.085,55.085,55.085
	h147.275l0.251-146.078h-37.951c-4.932,0-8.935-3.988-8.954-8.92l-0.182-47.087c-0.019-4.959,3.996-8.989,8.955-8.989h37.882
	v-45.498c0-52.8,32.247-81.55,79.348-81.55h38.65c4.945,0,8.955,4.009,8.955,8.955v39.704c0,4.944-4.007,8.952-8.95,8.955
	l-23.719,0.011c-25.615,0-30.575,12.172-30.575,30.035v39.389h56.285c5.363,0,9.524,4.683,8.892,10.009l-5.581,47.087
	c-0.534,4.506-4.355,7.901-8.892,7.901h-50.453l-0.251,146.078h87.631c30.422,0,55.084-24.662,55.084-55.084V55.085
	C408.786,24.662,384.124,0,353.701,0z"
              />
            </svg>
          </a>
          <a
            href={linkedinShare}
            target="_blank"
            rel="noopener noreferrer"
            className="linkedin-share"
          >
            <svg
              x="0px"
              y="0px"
              viewBox="0 0 382 382"
              style={{ enableBackground: "new 0 0 382 382" }}
            >
              <path
                style={{ fill: "#fff" }}
                d="M347.445,0H34.555C15.471,0,0,15.471,0,34.555v312.889C0,366.529,15.471,382,34.555,382h312.889
	C366.529,382,382,366.529,382,347.444V34.555C382,15.471,366.529,0,347.445,0z M118.207,329.844c0,5.554-4.502,10.056-10.056,10.056
	H65.345c-5.554,0-10.056-4.502-10.056-10.056V150.403c0-5.554,4.502-10.056,10.056-10.056h42.806
	c5.554,0,10.056,4.502,10.056,10.056V329.844z M86.748,123.432c-22.459,0-40.666-18.207-40.666-40.666S64.289,42.1,86.748,42.1
	s40.666,18.207,40.666,40.666S109.208,123.432,86.748,123.432z M341.91,330.654c0,5.106-4.14,9.246-9.246,9.246H286.73
	c-5.106,0-9.246-4.14-9.246-9.246v-84.168c0-12.556,3.683-55.021-32.813-55.021c-28.309,0-34.051,29.066-35.204,42.11v97.079
	c0,5.106-4.139,9.246-9.246,9.246h-44.426c-5.106,0-9.246-4.14-9.246-9.246V149.593c0-5.106,4.14-9.246,9.246-9.246h44.426
	c5.106,0,9.246,4.14,9.246,9.246v15.655c10.497-15.753,26.097-27.912,59.312-27.912c73.552,0,73.131,68.716,73.131,106.472
	L341.91,330.654L341.91,330.654z"
              />
            </svg>
          </a>
        </div>
      </div>
    )
  }
}
export default ShareIcons
