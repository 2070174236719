import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import PageTitle from "../components/PageTitle"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ShareIcons from "../components/ShareIcons"

class blog_template extends Component {
  toCapitalize(item) {
    let splitStr = item.toLowerCase().split("-")
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
    }
    return splitStr.join(" ")
  }
  render() {
    const article = this.props.data.markdownRemark

    let postedDate = new Date(`${article.frontmatter.date}`)
    let postedFixedDate = ` ${postedDate.getDate()}-${postedDate.getMonth() +
      1}-${postedDate.getFullYear()}`

    let seoKeywords = article.frontmatter.categories.concat(
      article.frontmatter.tags
    )
    let seoImage = article.frontmatter.thumbnail.childImageSharp.fixed.src

    return (
      <Layout>
        <Seo
          title={this.props.pageContext.title.replace(/^\w/, c =>
            c.toUpperCase()
          )}
          description={article.frontmatter.description}
          keywords={seoKeywords}
          image={seoImage}
          path={article.frontmatter.path}
        />
        <div>
          <PageTitle
            pageTitle={this.props.pageContext.title}
            author_name={article.frontmatter.author}
            published_date={postedFixedDate}
          />
          <div className="post-background">
            <div className="post-container">
              <div className="article">
                <div dangerouslySetInnerHTML={{ __html: article.html }} />
              </div>
              <div className="categories-container">
                Categories:{" "}
                {article.frontmatter.categories.map((category, index) => {
                  return (
                    <span className="filter-category" key={index}>
                      <Link to={`/category/${category.toLowerCase()}`}>
                        {this.toCapitalize(category)}
                      </Link>
                    </span>
                  )
                })}
              </div>
              <div className="tags-container">
                Tags:{" "}
                {article.frontmatter.tags.map((tag, index) => {
                  return (
                    <span className="filter-tag" key={index}>
                      <Link to={`/tag/${tag.toLowerCase()}`}>
                        {this.toCapitalize(tag)}
                      </Link>
                    </span>
                  )
                })}
              </div>
              <ShareIcons article={article} />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
export const postQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        author
        date
        title
        categories
        tags
        description
        thumbnail {
          childImageSharp {
            fixed(width: 295, height: 200) {
              base64
              width
              height
              src
              srcSet
            }
          }
        }
      }
    }
  }
`
export default blog_template
